.product {
  width: 100%;
  margin: 4rem auto;
  padding: 0 2rem;
}

/* PRODUCT CONTAINER */
.product_container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

/* PRODUCT IMAGE */
.product_img {
  width: 100vw;
  max-width: 40vw;
}

.product_img img {
  object-fit: contain;
  width: 100%;
  height: 500px;
}

/* PRODUCT DETAILS */
.product_details {
  width: 100vw;
  max-width: 50vw;
}

/* category text */
.product_category {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  color: #777;
  margin-bottom: 1rem;
}

/* product name */
.product_name {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
  color: #333;
  margin: 0 0 1rem;
}

/* product price */
.product_price {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 2.5rem;
  font-weight: 400;
  color: rgba(30, 144, 250, 0.8);
  margin-bottom: 1.25rem;
}

/* product description */
.product_desc {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  color: #555;
  margin-bottom: 2rem;
}

/* add to cart button */
.product_add {
  display: block;
  width: 200px;
  padding: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  border: solid 1px #333;
  background-color: white;
  transition: background 150ms ease-in-out;
}

.product_add:hover {
  background-color: #333;
  color: white;
}

/* SMALL SCREENS STYLES */
@media (max-width: 600px) {
  .product_container {
    flex-direction: column;
    align-items: center;
  }

  .product_img {
    max-width: 70vw;
    margin: 0 auto 2rem;
  }

  .product_details {
    max-width: 80vw;
  }
}
