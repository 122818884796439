.product-card {
  width: 100%;
  padding: 1rem;
  background-color: white;
  border: solid 1px rgba(168, 172, 176, 0.19);
  transition: all 150ms ease-in-out;
  cursor: pointer;
}

.product-card:hover {
  filter: drop-shadow(0px 4px 35px rgba(168, 172, 176, 0.19));
}

/* PRODUCT IMAGE STULES */
.product-card_img {
  width: 100%;
  object-position: 50% 50%;
  margin-bottom: 1.25rem;
}

.product-card_img img {
  object-fit: contain;
  width: 100%;
  height: 300px;
}

/* PRODUCT TITLE STYLES */
.product-card_title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  color: #333;
  margin: 0 0 1rem;
}

/* PRODUCT PRICE STYLES */
.product-card_price {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.5rem;
  font-weight: 400;
  color: rgba(30, 144, 250, 0.8);
  margin-bottom: 1.25rem;
}

/* ADD TO CART STYLES */
.product-card_add-to-cart {
  display: block;
  padding: 1rem;
  font-size: 1rem;
  cursor: pointer;
  color: #333;
  border: solid 1px #333;
  background-color: white;
  transition: background 150ms ease-in-out;
}

.product-card_add-to-cart:hover {
  background-color: #333;
  color: white;
}
