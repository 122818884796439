*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
}

a {
  display: inline-block;
  text-decoration: none;
  color: #333;
}
