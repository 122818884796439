.header {
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto 4rem;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

/* NAV BRAND STYLES  */
.header_brand {
  width: 40px;
  height: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  color: #1e90ff;
}

.header_brand img {
  width: 100%;
  height: auto;
}

/* CART BUTTON STYLES */
.header_cart {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: white;
  border: none;
  cursor: pointer;
  position: relative;
}

.header_cart img {
  width: 100%;
  height: auto;
}

.header_cart-count {
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  background-color: dodgerblue;
  position: absolute;
  top: 0;
  right: -0.5625rem;
  font-size: 1rem;
  font-family: Arial, Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-align: center;
  color: white;
}
